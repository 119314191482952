.spinning {
  -webkit-animation: spinning 2s infinite linear;
  animation: spinning 2s infinite linear;
}
@-webkit-keyframes spinning {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinning {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.PageSurveyList-Element-Sticky {
  position: fixed;
  top: 70px;
}

@media (min-width: 600px) {
  .QuestionType-Paper {
    display: block;
  }
  .QuestionType-Paper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 599px) {
  .QuestionType-Paper {
    display: inline-block;
  }
  .PageSurveyList-BtnPages,
  .QuestionType-Paper {
    position: fixed;
  }
}
.PageSurveyList-TypeList-Sticky-Mobile {
  background-color: #fff;
  right: 10px;
  bottom: 50px;
  z-index: 2;
}
.PageSurveyList-BtnPages-Sticky-Mobile {
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  overflow-x: auto;
}
