body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}
.table-title {
  font-size: 1.7rem;
  letter-spacing: 2px;
}

/*TODO remover css*/
iframe {
  display: none;
}

.MuiOutlinedInput-root.Mui-disabled.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336;
}

.cron_builder {
  max-width: 670px!important;
}

.dx-datagrid-column-chooser .dx-overlay-content {
  transform: none !important;
  right: 0px;
  left: auto !important;
}
